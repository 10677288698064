import { Component, Inject, signal, WritableSignal } from '@angular/core';
import { InitiatedActionService } from '../../../authentication/initiated-action.service';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog';
import { MatButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { OtpInfo } from '../../../model/employee/otp/otp-info';
import { EmployeeOtpService } from '../../../services/api/employee-otp.service';

@Component({
  selector: 'app-edit-authentication-dialog',
  standalone: true,
  imports: [
    MatButton,
    MatIcon,
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
  ],
  templateUrl: './edit-authentication-dialog.component.html',
  styleUrl: './edit-authentication-dialog.component.scss',
  providers: [EmployeeOtpService],
})
export class EditAuthenticationDialogComponent {
  otpEnabled$: WritableSignal<boolean>;
  constructor(
    private readonly employeeOtpService: EmployeeOtpService,
    private readonly dialogRef: MatDialogRef<EditAuthenticationDialogComponent>,
    private readonly initiatedActionService: InitiatedActionService,
    @Inject(MAT_DIALOG_DATA)
    private readonly data: EditAuthenticationDialogComponentData,
  ) {
    this.otpEnabled$ = signal(data.otpInfo.length > 0);
  }

  resetPassword() {
    this.initiatedActionService.resetPassword('/profile');
    this.dialogRef.close(true);
  }

  enableTwoFactor() {
    this.initiatedActionService.configureTotp('/profile');
    this.dialogRef.close(true);
  }

  disableTwoFactor() {
    this.employeeOtpService
      .deleteOtpInfo(this.data.otpInfo.map((x) => x.credentialId))
      .subscribe(() => {
        this.dialogRef.close(true);
      });
  }
}

export class EditAuthenticationDialogComponentData {
  otpInfo: OtpInfo[];
}
