import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { getPlatform, Platform } from '../../../detectPlatform';
import { Platform as CdkPlatform } from '@angular/cdk/platform';
import { DevicePermission } from '../../../api/gen';

@Injectable()
export class PermissionService {
  private currentMicStatus: AidarPermissionStatus =
    AidarPermissionStatus.Unknown;
  private currentCamStatus: AidarPermissionStatus =
    AidarPermissionStatus.Unknown;

  private readonly permissionChanges$ = new ReplaySubject<PermissionUpdate>(1);

  constructor(private readonly platform: CdkPlatform) {}

  private triggerPermissionUpdate(
    currentCameraPermission: AidarPermissionStatus,
    currentMicPermission: AidarPermissionStatus,
  ) {
    this.currentMicStatus = currentMicPermission;
    this.currentCamStatus = currentCameraPermission;
    if (this.currentCamStatus === AidarPermissionStatus.Granted) {
      this.listenToCameraPermissionChange();
    }
    if (this.currentMicStatus === AidarPermissionStatus.Granted) {
      this.listenToMicrophonePermissionChange();
    }
    this.triggerUpdate();
  }

  public permissionSuccess() {
    this.triggerPermissionUpdate(
      AidarPermissionStatus.Granted,
      AidarPermissionStatus.Granted,
    );
  }

  public detectSystemLevelDenied() {
    // This is needed in order to check if a permission was just denied on the "prompt" dialog or if
    // it they are denied on "app" / system level (and thus the user needs to give e.g. safari camera and mic
    // permissions in the system settings)
    // Initially, I wanted to check via the permissions api first (if available), and in case
    // a) Granted => nothing to do
    // b) Prompt => use getUserMedia to enforce decision
    // c) Denied => System level denied, as we do not even prompt
    // However, some folks at Apple decided that it is best to implement system level denied as follows:
    // query Permission gives prompt, enforcing it gives denied (same as when really prompting)
    // Yet, I found out that if we enforce it first and THEN use navigator query permission, we get 'prompt' in case
    // the user denied the permission in the dialog, and 'denied' if it is denied on system level
    // TLDR; Dont query via navigator first and use getUserMedia if needed, as in that case we cannot detect
    // system level blocked permissions on ios
    // By the way: On android the navigator query only gives us the users choice. However, even if we get granted,
    // this could still mean that it is denied on system level as it only reflects the users choice
    // at least, chrome on android gets us to the system settings, so we can leave our default instructions.
    if (getPlatform() === Platform.iOS && this.permissionApiSupported()) {
      const checkCamera = navigator['permissions'].query({
        name: 'camera' as PermissionName,
      });
      const checkMic = navigator['permissions'].query({
        name: 'microphone' as PermissionName,
      });
      Promise.all([checkCamera, checkMic]).then((x) => {
        this.triggerPermissionUpdate(
          this.castState(x[0].state, true),
          this.castState(x[1].state, true),
        );
      });
    } else {
      this.permissionDenied();
    }
    this.permissionDenied();
  }

  private permissionDenied() {
    this.triggerPermissionUpdate(
      AidarPermissionStatus.Denied,
      AidarPermissionStatus.Denied,
    );
  }

  public observeChanges(): Observable<PermissionUpdate> {
    return this.permissionChanges$.asObservable();
  }

  private listenToCameraPermissionChange() {
    if (this.permissionApiSupported()) {
      const permissionName = 'camera' as PermissionName;
      navigator['permissions']
        .query({
          name: permissionName,
        })
        .then((result) => {
          result.onchange = () => {
            this.currentCamStatus = this.castState(result?.state);
            this.triggerUpdate();
          };
        });
    }
  }

  private listenToMicrophonePermissionChange() {
    if (this.permissionApiSupported()) {
      const permissionName = 'microphone' as PermissionName;
      navigator['permissions']
        .query({
          name: permissionName,
        })
        .then((result) => {
          result.onchange = () => {
            this.currentMicStatus = this.castState(result?.state);
            this.triggerUpdate();
          };
        });
    }
  }

  private castState(
    state: PermissionState,
    isIOSEnforcementQuery = false,
  ): AidarPermissionStatus {
    if (state == 'granted') {
      return AidarPermissionStatus.Granted;
    } else if (state == 'denied') {
      return isIOSEnforcementQuery
        ? AidarPermissionStatus.DeniedOnSystemLevel
        : AidarPermissionStatus.Denied;
    } else if (state == 'prompt') {
      return AidarPermissionStatus.Prompt;
    }
    return AidarPermissionStatus.PermissionApiUnavailable;
  }

  private triggerUpdate() {
    this.permissionChanges$.next(
      new PermissionUpdate(this.currentMicStatus, this.currentCamStatus),
    );
  }

  private permissionApiSupported() {
    return (
      navigator &&
      navigator['permissions'] &&
      navigator['permissions'].query &&
      // https://stackoverflow.com/questions/53147944/firefox-permission-name-member-of-permissiondescriptor-camera-is-not-a-vali
      !this.platform.FIREFOX
    );
  }
}

export class PermissionUpdate {
  constructor(
    public readonly currentMicStatus: AidarPermissionStatus,
    public readonly currentCamStatus: AidarPermissionStatus,
  ) {}

  isMicGiven(): boolean {
    return AidarPermissionStatus.isGiven(this.currentMicStatus);
  }

  isCamGiven(): boolean {
    return AidarPermissionStatus.isGiven(this.currentCamStatus);
  }
}

export enum AidarPermissionStatus {
  Denied = 0,
  Granted = 1,
  Prompt = 2,
  PermissionApiUnavailable = 3,
  Unknown = 4,
  DeniedOnSystemLevel,
}

export namespace AidarPermissionStatus {
  export function isGiven(status: AidarPermissionStatus): boolean {
    return (
      status === AidarPermissionStatus.Prompt ||
      status === AidarPermissionStatus.Granted
    );
  }
  export function castToDto(status: AidarPermissionStatus): DevicePermission {
    switch (status) {
      case AidarPermissionStatus.DeniedOnSystemLevel:
        return DevicePermission.DeniedOnSystemLevel;
      case AidarPermissionStatus.Denied:
        return DevicePermission.Denied;
      case AidarPermissionStatus.Granted:
        return DevicePermission.Granted;
      case AidarPermissionStatus.PermissionApiUnavailable:
        return DevicePermission.PermissionApiUnavailable;
      case AidarPermissionStatus.Prompt:
        return DevicePermission.Prompt;
      case AidarPermissionStatus.Unknown:
      default:
        return DevicePermission.Unknown;
    }
  }
}
