import { Injectable } from '@angular/core';
import {
  AnalyticsService as api,
  FeatureTrackingService,
} from '../../../api/gen';
import { VideoChatService } from '../../video-appointment/services/videochat.service';
import { distinct, filter, Observable, take, takeUntil } from 'rxjs';
import { distinctUntilChanged, map, mergeMap } from 'rxjs/operators';
import {
  Devices,
  DeviceService,
} from '../../video-appointment/services/device.service';
import {
  AidarPermissionStatus,
  PermissionService,
} from '../../video-appointment/services/permission.service';
import { Mode } from '../../annotations/drawing-canvas/drawing-canvas.component';
import { MediaTrackService } from '../../video-appointment/services/track-ctrl/media-track.service';

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  constructor(
    private readonly analyticsRepo: api,
    private readonly trackingRepo: FeatureTrackingService,
    private readonly deviceService: DeviceService,
    private readonly videoService: VideoChatService,
    localTrackService: MediaTrackService,
    permissionService: PermissionService,
  ) {
    videoService.activeRoom$
      .pipe(
        map((x) => x?.data?.inquiryIdentifier),
        filter((x) => !!x),
        distinct(),
      )
      .subscribe((value) => {
        this.reportClientInfo(value);
        // we want to report device changes as well
        this.deviceService.devicesUpdated$
          .pipe(distinct())
          .pipe(takeUntil(videoService.activeRoom$.pipe(filter((x) => !x))))
          .pipe(
            mergeMap((devices) => this.reportAvailableDevices(value, devices)),
          )
          .subscribe();
      });

    localTrackService.videoTrackUpdated$
      .pipe(
        filter((x) => !!x),
        map((x) => x.getMediaStreamTrack()?.label),
        mergeMap((x) =>
          videoService.activeRoom$.pipe(
            filter((x) => !!x?.data?.inquiryIdentifier),
            take(1),
            map((room) => {
              return { label: x, id: room?.data?.inquiryIdentifier };
            }),
          ),
        ),
        // even if label does not change, if room does we want to report it again
        distinctUntilChanged(),
      )
      .subscribe((x) => {
        this.reportUsedCamera(x.id, x.label);
      });

    videoService.localDrawEvent$.subscribe((x) => {
      if (x.mode === Mode.Delete) this.reportDrawEvent();
    });

    permissionService
      .observeChanges()
      .pipe(
        distinctUntilChanged(),
        mergeMap((x) =>
          videoService.activeRoom$.pipe(
            filter((x) => !!x?.data?.inquiryIdentifier),
            take(1),
            map((room) => {
              return {
                id: room?.data?.inquiryIdentifier,
                cam: x.currentCamStatus,
                mic: x.currentMicStatus,
              };
            }),
          ),
        ),
      )
      .subscribe((x) => {
        this.reportPermissionUpdate(x.id, x.cam, x.mic);
      });
  }

  private reportDrawEvent(): void {
    this.videoService.activeRoom$
      .pipe(
        filter((x) => !!x?.data?.inquiryIdentifier),
        map((x) => x.data?.inquiryIdentifier),
        take(1),
        mergeMap((id) => {
          return this.trackingRepo.featureTrackingDrawAgentPost({
            inquiryId: id,
          });
        }),
      )
      .subscribe();
  }

  private reportAvailableDevices(
    inquiryId: string,
    devices: Devices,
  ): Observable<void> {
    return this.analyticsRepo.analyticsAvailableDevicesAgentPost({
      inquiryId: inquiryId,
      devices: devices?.map((x) => {
        return { label: x.label, kind: x.kind };
      }),
    });
  }

  private reportClientInfo(inquiryId: string): void {
    const ua = navigator.userAgent;
    const language = navigator.language;

    this.analyticsRepo
      .analyticsClientInfoAgentPost({
        inquiryId: inquiryId,
        language: language,
        userAgent: ua,
      })
      .subscribe();
  }

  private reportUsedCamera(inquiryId: string, cameraLabel: string): void {
    this.analyticsRepo
      .analyticsUsedCameraAgentPost({
        inquiryId: inquiryId,
        label: cameraLabel,
      })
      .subscribe();
  }

  private reportPermissionUpdate(
    inquiryId: string,
    currentCamStatus: AidarPermissionStatus,
    currentMicStatus: AidarPermissionStatus,
  ): void {
    this.analyticsRepo
      .analyticsPermissionUpdateAgentPost({
        inquiryId: inquiryId,
        cameraPermission: AidarPermissionStatus.castToDto(currentCamStatus),
        micPermission: AidarPermissionStatus.castToDto(currentMicStatus),
      })
      .subscribe();
  }
}
