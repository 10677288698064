import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { CameraComponent } from '../shared/camera/camera.component';
import { Devices, DeviceService, DeviceType } from '../services/device.service';
import { DeviceSelectComponent } from './device-select.component';
import {
  Diagnostics,
  DiagnosticsService,
} from '../services/diagnostics/diagnostics.service';
import { AidarPermissionStatus } from '../services/permission.service';

@Component({
  selector: 'app-settings',
  styleUrls: ['./settings.component.scss'],
  templateUrl: './settings.component.html',
})
export class SettingsComponent implements OnInit, OnDestroy {
  devices: DeviceOptions = new DeviceOptions();
  @ViewChild('camera') camera: CameraComponent;
  @ViewChild('videoSelect') video: DeviceSelectComponent;
  @Input() isPreviewing: boolean;
  @Output() settingsChanged = new EventEmitter<MediaDeviceInfo>();
  private readonly unsubscribe$ = new Subject<void>();

  public isOutputSelectionPossible = true;

  private lastDiagnostics?: Diagnostics;

  protected selectedVideo: string;
  protected selectedAudioIn: string;
  protected selectedAudioOut: string;

  constructor(
    private readonly deviceService: DeviceService,
    private readonly diagnosticsService: DiagnosticsService,
  ) {
    this.isOutputSelectionPossible = deviceService.outputSelectionPossible;
    this.selectedVideo = deviceService.getSelectedVideoDeviceId();
    this.selectedAudioIn = deviceService.getSelectedAudioDeviceId();
    this.selectedAudioOut = deviceService.getSelectedAudioOutputDevice();
  }

  ngOnInit() {
    this.deviceService.devicesUpdated$
      .pipe(debounceTime(350))
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(async (deviceListPromise) => {
        const devices = await deviceListPromise;
        this.propagateDeviceOptions(devices);
        this.handleDeviceAvailabilityChanges();
      });
    this.diagnosticsService.diagnosticsChanged$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((x) => {
        this.lastDiagnostics = x;
      });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  async onSettingsChanged(deviceInfo: MediaDeviceInfo) {
    this.settingsChanged.emit(deviceInfo);
  }

  public propagateDeviceOptions(devices: Devices) {
    this.devices.micOptions = this.getDeviceByKind(devices, 'audioinput');
    this.devices.camOptions = this.getDeviceByKind(devices, 'videoinput');
    this.devices.speakerOptions = this.getDeviceByKind(devices, 'audiooutput');
  }

  private getDeviceByKind(
    deviceOptions: Devices,
    kind: MediaDeviceKind,
  ): MediaDeviceInfo[] {
    if (kind === 'audiooutput' && !this.isOutputSelectionPossible)
      return [this.getDisabledOption(kind)];
    const devices = deviceOptions.filter((device) => device.kind === kind);
    if (devices.length < 1) {
      devices.push(this.getDisabledOption(kind));
    }
    return devices;
  }

  async playTestSound(): Promise<void> {
    const test = new Audio(
      'https://www2.cs.uic.edu/~i101/SoundFiles/CantinaBand3.wav',
    );
    await test.play();
  }

  private handleDeviceAvailabilityChanges() {
    if (this.devices?.camOptions.length && this.video?.selectedId) {
      let videoDevice = this.devices?.camOptions.find(
        (d) => d.deviceId === this.video.selectedId,
      );
      if (!videoDevice) {
        videoDevice = this.devices?.camOptions.find(
          (d) => d.kind === DeviceType.VIDEO_INPUT,
        );
        if (videoDevice) {
          this.video.selectedId = videoDevice.deviceId;
          this.onSettingsChanged(videoDevice);
        }
      }
    }
  }

  private getDisabledOption(kind: MediaDeviceKind): MediaDeviceInfo {
    return {
      deviceId: '',
      label: this.getRightLabelForProblem(kind),
      groupId: '',
      kind: kind,
      toJSON(): any {},
    };
  }

  private getRightLabelForProblem(kind: MediaDeviceKind): string {
    if (kind === 'audiooutput') {
      return $localize`Systemstandard`;
    }
    let permissionGiven = false;
    let deviceAvailable = false;
    if (kind === 'audioinput') {
      permissionGiven = AidarPermissionStatus.isGiven(
        this.lastDiagnostics.localMicrophonePermissionStatus,
      );
      deviceAvailable = this.lastDiagnostics.localMicrophoneDevicesAvailable;
    } else if (kind === 'videoinput') {
      permissionGiven = AidarPermissionStatus.isGiven(
        this.lastDiagnostics.localCameraPermissionStatus,
      );
      deviceAvailable = this.lastDiagnostics.localMicrophoneDevicesAvailable;
    }
    if (!deviceAvailable) return $localize`Kein Gerät angeschlossen`;
    if (!permissionGiven) return $localize`Berechtigung nicht erteilt`;

    return $localize`Unbekannter Fehler`;
  }
}

class DeviceOptions {
  public speakerOptions: MediaDeviceInfo[] = [];
  public camOptions: MediaDeviceInfo[] = [];
  public micOptions: MediaDeviceInfo[] = [];
}
