<h2 class="dialog-title ns" mat-dialog-title i18n>Einstellungen</h2>
<mat-dialog-content>
  <div class="content">
    <div class="previews">
      <app-camera-settings-preview></app-camera-settings-preview>
      <app-audio-visualization></app-audio-visualization>
    </div>
    <div class="options">
      <div>
        <app-device-select
          #videoSelect
          [selectedId]="selectedVideo"
          (settingsChanged)="onSettingsChanged($event)"
          [devices]="devices.camOptions"
          [kind]="'videoinput'"
          i18n-label
          label="Video Eingang"
          key="videoInputId"
        ></app-device-select>
      </div>
      <div>
        <app-device-select
          (settingsChanged)="onSettingsChanged($event)"
          [devices]="devices.micOptions"
          [kind]="'audioinput'"
          [selectedId]="selectedAudioIn"
          i18n-label
          label="Audio Eingang"
          key="audioInputId"
        ></app-device-select>
      </div>
      <div>
        <app-device-select
          [kind]="'audiooutput'"
          [selectedId]="selectedAudioOut"
          i18n-label
          label="Audio Ausgang"
          [disabled]="!isOutputSelectionPossible"
          [devices]="devices.speakerOptions"
          (settingsChanged)="onSettingsChanged($event)"
          key="audioOutputId"
        ></app-device-select>
      </div>
    </div>
  </div>
</mat-dialog-content>
