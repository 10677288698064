import { ApplicationSettingsDto } from '../../../api/gen';

export class ApplicationSettings {
  customersCanUploadFilesDuringCall?: boolean;
  customDeleteDataAfterDays?: number | null;
  validateScreenshotSharing?: boolean;

  static fromDto(dto: ApplicationSettingsDto): ApplicationSettings {
    return {
      customersCanUploadFilesDuringCall: dto.customersCanUploadFilesDuringCall,
      customDeleteDataAfterDays: dto.customDeleteDataAfterDays,
      validateScreenshotSharing: dto.validateScreenshotSharing,
    } as ApplicationSettings;
  }
}
