import { Component } from '@angular/core';

@Component({
  selector: 'app-start-appointment-expansion-panel',
  templateUrl: './start-appointment-expansion-panel.component.html',
  styleUrl: './start-appointment-expansion-panel.component.scss',
})
export class StartAppointmentExpansionPanelComponent {
  isSmsPanelOpened = true;
  isInvitationLinkPanelOpen = false;

  onInvitationLinkPanelOpened() {
    this.isSmsPanelOpened = false;
    this.isInvitationLinkPanelOpen = true;
  }

  onSmsLinkPanelOpened() {
    this.isSmsPanelOpened = true;
    this.isInvitationLinkPanelOpen = false;
  }
}
