import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MediaTrackService } from '../../services/track-ctrl/media-track.service';
import { AidarVideoTrack } from '../../services/track-ctrl/aidar-media-track';

@Component({
  selector: 'app-camera',
  styleUrls: ['./camera.component.scss'],
  templateUrl: './camera.component.html',
})
export class CameraComponent implements AfterViewInit, OnDestroy {
  @ViewChild('preview') previewElement: ElementRef;

  @Input()
  size: string;

  isInitializing = true;

  private readonly unsubscribe$ = new Subject<void>();

  constructor(
    private readonly trackCtrl: MediaTrackService,
    private readonly renderer: Renderer2,
  ) {}

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  async ngAfterViewInit() {
    if (this.previewElement?.nativeElement) {
      this.trackCtrl.videoTrackUpdated$
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((x) => {
          this.initializeDevice(x);
        });
    }
  }

  private initializeDevice(videoTrack: AidarVideoTrack) {
    try {
      this.isInitializing = true;
      const videoElement = videoTrack.attach();
      videoElement.style.transform = 'scale(-1,1)';
      videoElement.style.borderRadius = '50%';
      videoElement.style.objectFit = 'cover';
      this.renderer.setStyle(videoElement, 'height', this.size ?? '10rem');
      this.renderer.setStyle(videoElement, 'width', this.size ?? '10rem');
      this.renderer.appendChild(
        this.previewElement.nativeElement,
        videoElement,
      );
    } catch (error) {
      if (error instanceof DOMException && error.name === 'NotAllowedError') {
        //Permission for Video / Audio is not granted
      }
    } finally {
      this.isInitializing = false;
    }
  }
}
