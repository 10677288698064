import { Component, OnInit } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { Observable, scan, takeWhile, timer } from 'rxjs';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { AsyncPipe } from '@angular/common';

@Component({
  selector: 'app-live-image-in-progress-overlay',
  standalone: true,
  imports: [MatIcon, AsyncPipe],
  templateUrl: './live-image-in-progress-overlay.component.html',
  styleUrl: './live-image-in-progress-overlay.component.scss',
  animations: [
    trigger('animationState', [
      state(
        'default',
        style({
          visibility: 'hidden',
        }),
      ),
      state(
        'countdown',
        style({
          visibility: 'visible',
        }),
      ),

      transition('default => countdown', animate('400ms ease-in')),
      transition('countdown => default', animate('400ms ease-out')),
    ]),
  ],
})
export class LiveImageInProgressOverlayComponent implements OnInit {
  protected countDown$: Observable<number>;

  animationState = 'default';

  constructor() {}

  ngOnInit(): void {
    this.animationState = 'default';
  }

  showRecording() {
    this.animationState = 'countdown';
    this.countDown$ = timer(0, 1000).pipe(
      scan((acc) => --acc, 6),
      takeWhile((x) => x >= 0),
    );

    this.countDown$.subscribe((value) => {
      if (value === 0) {
        this.animationState = 'default';
      }
    });
  }
}
