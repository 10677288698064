import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { debounceTime, delay, distinctUntilChanged, skip, Subject } from 'rxjs';
import { environment } from '../../../environments/environment';
import { NotesDocumentationService } from '../../services/api/notes-documentation.service';
import { UpdateNotesDocumentationRequest } from '../../model/notes/request/update-notes-documentation-request';

@Component({
  selector: 'app-call-notes',
  templateUrl: './call-notes.component.html',
  styleUrls: ['./call-notes.component.scss'],
  providers: [NotesDocumentationService],
})
export class CallNotesComponent implements OnInit, OnChanges {
  @Input()
  public inquiryId: string;
  @Input()
  public enabled: boolean;
  @Input()
  public useMiniVersion = false;

  // Maybe we should set this dynamically once the initial loading is done
  public textForm = new FormGroup({
    notes: new FormControl(''),
  });
  protected htmlContent: string;
  protected apiKey = environment.editorApiKey;
  protected options: any;
  protected notesLoadingCount = 0;
  protected loadingCount$ = new Subject<number>();

  constructor(private readonly notesService: NotesDocumentationService) {}

  ngOnInit(): void {
    this.enabled || this.textForm.disable();

    this.refresh();

    this.textForm
      .get('notes')
      .valueChanges.pipe(skip(1), debounceTime(500), distinctUntilChanged())
      .subscribe((note) => {
        this.loadingCount$.next(++this.notesLoadingCount);
        const request = { note: note ?? '' } as UpdateNotesDocumentationRequest;
        this.notesService
          .updateNotesDocumentation(this.inquiryId, request)
          .pipe(delay(1000))
          .subscribe({
            next: () => {
              this.loadingCount$.next(--this.notesLoadingCount);
            },
            error: () => {
              this.loadingCount$.next(--this.notesLoadingCount);
            },
          });
      });

    this.options = this.useMiniVersion
      ? {
          menubar: '',
          plugins: 'lists table',
          statusbar: false,
          language: 'de',
          resize: false,
          toolbar: 'bold italic underline | bullist numlist | ',
        }
      : {
          menubar: '',
          plugins: 'lists table',
          statusbar: false,
          language: 'de',
          resize: false,
          toolbar:
            'blocks | bold italic underline | bullist numlist | ' +
            'forecolor backcolor | table',
        };
  }

  onResizeEditor() {}

  public refresh(): void {
    this.loadingCount$.next(++this.notesLoadingCount);
    this.notesService.getNotesDocumentation(this.inquiryId).subscribe((x) => {
      this.textForm.get('notes').setValue(x?.note ?? '');
      this.loadingCount$.next(--this.notesLoadingCount);
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['enabled']) {
      if (this.enabled === true) {
        this.textForm.enable({ emitEvent: false });
      } else {
        this.textForm.disable({ emitEvent: false });
      }
    }
  }
}
