import { Component, Input } from '@angular/core';
import { Employee } from '../../model/employee/employee';

@Component({
  selector: 'app-personal-information',
  templateUrl: './personal-information.component.html',
  styleUrls: ['./personal-information.component.scss'],
})
export class PersonalInformationComponent {
  @Input() employee: Employee;
}
