import { Injectable } from '@angular/core';
import { LocationResult } from './location-result';
import { Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class LocationDataService {
  locationUpdated$ = new Subject<LocationResult | null>();

  public locationReceived(locationResult?: LocationResult) {
    this.locationUpdated$.next(locationResult);
  }
}
