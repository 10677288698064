<h2 mat-dialog-title i18n>Authentifizierungsoptionen ändern</h2>
<mat-dialog-content class="edit-authentication-container">
  <button mat-flat-button color="primary" i18n (click)="resetPassword()">
    Passwort ändern
  </button>

  @if (otpEnabled$()) {
    <button mat-flat-button color="warn" i18n (click)="disableTwoFactor()">
      2-Faktor-Authentifizierung deaktivieren
    </button>
  } @else {
    <button mat-flat-button color="primary" i18n (click)="enableTwoFactor()">
      2-Faktor-Authentifizierung aktivieren
    </button>
  }
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close color="warn">
    <mat-icon>close</mat-icon>
    <span i18n>Abbrechen</span>
  </button>
</mat-dialog-actions>
