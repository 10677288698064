import { Component, OnDestroy, signal } from '@angular/core';
import {
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog';
import { MatButton } from '@angular/material/button';
import { LocationDataService } from '../../services/location/location-data.service';
import { Subject, takeUntil } from 'rxjs';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import {
  DataTrackObject,
  DataTrackObjectType,
  DataTrackService,
} from '../../services/track-ctrl/data-track.service';

@Component({
  selector: 'app-request-location-dialog',
  standalone: true,
  imports: [
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatButton,
    MatDialogClose,
    MatProgressSpinner,
  ],
  templateUrl: './request-location-dialog.component.html',
  styleUrl: './request-location-dialog.component.scss',
})
export class RequestLocationDialogComponent implements OnDestroy {
  private readonly unsubscribe$ = new Subject<void>();
  isLoading$ = signal(false);

  constructor(
    private readonly dialogRef: MatDialogRef<RequestLocationDialogComponent>,
    public readonly trackControl: DataTrackService,
    private readonly locationDataService: LocationDataService,
  ) {
    this.locationDataService.locationUpdated$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res) => {
        this.dialogRef.close(res);
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  permissionRequestAnnounced() {
    this.isLoading$.set(true);
    this.trackControl.sendDataObject(
      DataTrackObject.withType(DataTrackObjectType.LOCATION_REQUEST),
    );
  }
}
