import { EmployeeOtpService as Api } from '../../../api/gen';
import { map, Observable } from 'rxjs';
import { OtpInfo } from '../../model/employee/otp/otp-info';
import { DeleteOtpRequest } from '../../model/employee/otp/request/delete-otp-request';
import { Injectable } from '@angular/core';

@Injectable()
export class EmployeeOtpService {
  constructor(private readonly employeeOtpService: Api) {}

  getOtpInfo(): Observable<OtpInfo[]> {
    return this.employeeOtpService
      .employeeOtpGet()
      .pipe(map((x) => x.map((dto) => OtpInfo.fromDto(dto))));
  }

  deleteOtpInfo(identifiers: string[]): Observable<unknown> {
    const request = { otpIdentifiers: identifiers } as DeleteOtpRequest;
    return this.employeeOtpService.employeeOtpDeletePost(
      DeleteOtpRequest.toRequest(request),
    );
  }
}
