import { Component, Input, Signal } from '@angular/core';
import { AsyncPipe, NgIf } from '@angular/common';
import { MatProgressSpinner } from '@angular/material/progress-spinner';

@Component({
  selector: 'app-invitation-preview',
  standalone: true,
  imports: [AsyncPipe, MatProgressSpinner, NgIf],
  templateUrl: './invitation-preview.component.html',
  styleUrl: './invitation-preview.component.scss',
})
export class InvitationPreviewComponent {
  @Input() isLoading: Signal<boolean>;
  @Input() invitationText: Signal<string>;
  @Input() title: string = $localize`Vorschau der SMS Einladung`;
  @Input() titleVisible = true;
}
