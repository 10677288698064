import { Subject } from 'rxjs';

export interface AidarMediaTrack {
  attach(): HTMLMediaElement;

  destroy(): void;

  getMediaStreamTrack(): MediaStreamTrack;
}

export class AidarVideoTrack implements AidarMediaTrack {
  private readonly _enabledState$ = new Subject<boolean>();
  public enabledChanged$ = this._enabledState$.asObservable();
  attachedElements: HTMLMediaElement[] = [];
  public torchAvailable = false;

  constructor(private readonly _mediaStreamTrack: MediaStreamTrack) {
    const capabilities = _mediaStreamTrack.getCapabilities() as any;
    this.torchAvailable = capabilities.torch;
  }

  getMediaStreamTrack(): MediaStreamTrack {
    return this._mediaStreamTrack;
  }

  destroy(): void {
    this._mediaStreamTrack.enabled = false;
    this._mediaStreamTrack.stop();
    this.attachedElements.forEach((element) => {
      element.remove();
    });
    this.attachedElements = [];
  }

  attach(): HTMLMediaElement {
    const videoElement = document.createElement('video');
    videoElement.srcObject = new MediaStream([this._mediaStreamTrack]);
    videoElement.disablePictureInPicture = true;
    videoElement.autoplay = true;
    videoElement.playsInline = true;
    this.attachedElements.push(videoElement);
    return videoElement;
  }

  public toggleTorch(turnOn: boolean) {
    if (!this.torchAvailable) return;
    (this._mediaStreamTrack as any).applyConstraints({
      advanced: [{ torch: turnOn }],
    });
  }

  enable() {
    this._mediaStreamTrack.enabled = true;
    this._enabledState$.next(this._mediaStreamTrack.enabled);
  }

  disable() {
    this._mediaStreamTrack.enabled = false;
    this._enabledState$.next(this._mediaStreamTrack.enabled);
  }

  isEnabled(): boolean {
    return this._mediaStreamTrack.enabled;
  }
}

export class AidarAudioTrack implements AidarMediaTrack {
  private readonly _enabledState$ = new Subject<boolean>();
  public enabledChanged$ = this._enabledState$.asObservable();

  constructor(private readonly _mediaStreamTrack: MediaStreamTrack) {}

  getMediaStreamTrack(): MediaStreamTrack {
    return this._mediaStreamTrack;
  }

  attach(): HTMLMediaElement {
    throw new Error('Method not implemented.');
  }

  destroy(): void {
    this._mediaStreamTrack.enabled = false;
    this._mediaStreamTrack.stop();
  }

  enable() {
    this._mediaStreamTrack.enabled = true;
    this._enabledState$.next(this._mediaStreamTrack.enabled);
  }

  disable() {
    this._mediaStreamTrack.enabled = false;
    this._enabledState$.next(this._mediaStreamTrack.enabled);
  }

  isEnabled(): boolean {
    return this._mediaStreamTrack.enabled;
  }
}
