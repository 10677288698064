import { filter, map, Observable, Subject } from 'rxjs';
import { SignalingService } from '../signaling.service';
import { AppointmentCommands } from '../commands/appointment-commands';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class AppointmentMessagingService {
  private readonly messageStream = new Subject<Message>();

  constructor(private readonly signalingService: SignalingService) {}

  public onMessage(type: AppointmentMessageType): Observable<any> {
    return this.messageStream.asObservable().pipe(
      filter((x) => x.type === type),
      map((x) => x.data),
    );
  }

  public addAppointmentListener(): void {
    const hubConnection = this.signalingService.getHubConnection();
    hubConnection.on(AppointmentCommands.SYNC, (data) => {
      this.messageStream.next(new Message(AppointmentMessageType.Sync, data));
    });
    hubConnection.on(AppointmentCommands.SYNC_ATTACHMENTS, (data) => {
      this.messageStream.next(
        new Message(AppointmentMessageType.Sync_Attachments, data),
      );
    });
    hubConnection.on(AppointmentCommands.HANG_UP, (data) => {
      this.messageStream.next(
        new Message(AppointmentMessageType.Hang_Up, data),
      );
    });
    hubConnection.on(AppointmentCommands.IS_RECORDING, (data) => {
      this.messageStream.next(
        new Message(AppointmentMessageType.Is_Recording, data),
      );
    });
    hubConnection.on(AppointmentCommands.INVITATION_STATUS_CHANGED, (data) => {
      this.messageStream.next(
        new Message(AppointmentMessageType.Invitation_Status_Changed, data),
      );
    });
    hubConnection.on(AppointmentCommands.PUBLISH_NEW_CHAT_MESSAGE, (data) => {
      this.messageStream.next(
        new Message(AppointmentMessageType.Publish_New_Chat_Message, data),
      );
    });
    hubConnection.on(AppointmentCommands.CANCEL_CREATE_SCREENSHOT, (data) => {
      this.messageStream.next(
        new Message(AppointmentMessageType.CancelCreateScreenshot, data),
      );
    });
  }
}

class Message {
  constructor(
    public type: AppointmentMessageType,
    public data: any,
  ) {}
}

export enum AppointmentMessageType {
  Sync,
  Sync_Attachments,
  Hang_Up,
  Is_Recording,
  Invitation_Status_Changed,
  Publish_New_Chat_Message,
  CancelCreateScreenshot,
}
