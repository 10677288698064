<h2 mat-dialog-title i18n>Auf Berechtigung hinweisen</h2>
<mat-dialog-content>
  <span i18n>
    Bitte weisen Sie Ihren Gesprächspartner darauf hin, dass er die Berechtigung
    zur Abfrage seines Standortes im nächsten Schritt erteilen muss.</span
  >

  @if (isLoading$()) {
    <div class="loading-container">
      <mat-spinner diameter="20"></mat-spinner>
      <span class="loading-container-text" i18n>Warte auf Zustimmung</span>
    </div>
  }
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button color="warn" mat-dialog-close i18n>Abbrechen</button>
  <button
    mat-flat-button
    color="primary"
    i18n
    [disabled]="isLoading$()"
    (click)="permissionRequestAnnounced()"
  >
    Auf Berechtigung hingewiesen
  </button>
</mat-dialog-actions>
