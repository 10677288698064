import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import {
  InquiryDashboardService,
  InquiryState,
} from 'projects/helper-client/src/api/gen';
import { map } from 'rxjs';
import { InquirySummary } from '../models/inquiry';
import { DashboardInformationService } from '../services/dashboard-information.service';
import { InquiryDashboardSummary } from '../../model/inquiry/inquiry-dashboard-summary';

@Component({
  selector: 'app-latest-calls',
  templateUrl: './latest-calls.component.html',
  styleUrls: ['./latest-calls.component.scss'],
})
export class LatestCallsComponent implements OnInit {
  protected loading = false;
  protected dataSource = new MatTableDataSource<InquirySummary>([]);

  protected InquiryState = InquiryState;

  protected displayedColumns: string[] = [
    'state',
    'firstName',
    'scheduledFor',
    'assignedEmployeeDisplayName',
    'details',
    'inquiryActive',
  ];

  constructor(
    private readonly cdr: ChangeDetectorRef,
    private readonly inquiryService: DashboardInformationService,
    private readonly dashboardService: InquiryDashboardService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
  ) {}

  ngOnInit(): void {
    this.refreshInquiries();
  }

  private refreshInquiries() {
    this.loading = true;
    this.cdr.markForCheck();

    this.dashboardService
      .contactInquiryDashboardPost({
        limitToCount: 10,
      })
      .pipe(
        map((x) => {
          return x.map((y) =>
            InquirySummary.fromInquiryDashboardSummary(
              InquiryDashboardSummary.fromDto(y),
            ),
          );
        }),
      )
      .subscribe({
        next: (values) => {
          this.loading = false;

          this.dataSource.data = [...values];
          this.cdr.markForCheck();
        },
        error: (error) => {
          console.error(error);
          this.loading = false;
          this.cdr.markForCheck();
        },
      });
  }

  protected onInquirySelected(inquiry: InquirySummary) {
    this.router.navigate(['details'], {
      queryParams: { inquiryId: inquiry.inquiryIdentifier },
      queryParamsHandling: 'preserve',
      relativeTo: this.route,
    });
  }

  protected isActiveCall(inquiry: InquirySummary): boolean {
    return (
      inquiry.state === InquiryState.Open &&
      inquiry.scheduledFor &&
      !inquiry.endedAt &&
      new Date(inquiry.scheduledFor) <= new Date()
    );
  }
}
