export * from './analytics.service';
import { AnalyticsService } from './analytics.service';
export * from './applicationSettings.service';
import { ApplicationSettingsService } from './applicationSettings.service';
export * from './appointment.service';
import { AppointmentService } from './appointment.service';
export * from './appointmentDashboard.service';
import { AppointmentDashboardService } from './appointmentDashboard.service';
export * from './appointmentRecording.service';
import { AppointmentRecordingService } from './appointmentRecording.service';
export * from './assignInquiry.service';
import { AssignInquiryService } from './assignInquiry.service';
export * from './attachment.service';
import { AttachmentService } from './attachment.service';
export * from './attachmentCv.service';
import { AttachmentCvService } from './attachmentCv.service';
export * from './authenticationToken.service';
import { AuthenticationTokenService } from './authenticationToken.service';
export * from './brandingColor.service';
import { BrandingColorService } from './brandingColor.service';
export * from './brandingInvitation.service';
import { BrandingInvitationService } from './brandingInvitation.service';
export * from './company.service';
import { CompanyService } from './company.service';
export * from './companyAddress.service';
import { CompanyAddressService } from './companyAddress.service';
export * from './companyLogin.service';
import { CompanyLoginService } from './companyLogin.service';
export * from './distance.service';
import { DistanceService } from './distance.service';
export * from './emailAvailable.service';
import { EmailAvailableService } from './emailAvailable.service';
export * from './employee.service';
import { EmployeeService } from './employee.service';
export * from './employeeInvite.service';
import { EmployeeInviteService } from './employeeInvite.service';
export * from './employeeOtp.service';
import { EmployeeOtpService } from './employeeOtp.service';
export * from './featureTracking.service';
import { FeatureTrackingService } from './featureTracking.service';
export * from './initializationStatus.service';
import { InitializationStatusService } from './initializationStatus.service';
export * from './inquiry.service';
import { InquiryService } from './inquiry.service';
export * from './inquiryAccess.service';
import { InquiryAccessService } from './inquiryAccess.service';
export * from './inquiryDashboard.service';
import { InquiryDashboardService } from './inquiryDashboard.service';
export * from './inquiryEdit.service';
import { InquiryEditService } from './inquiryEdit.service';
export * from './inquirySettings.service';
import { InquirySettingsService } from './inquirySettings.service';
export * from './invitationStatus.service';
import { InvitationStatusService } from './invitationStatus.service';
export * from './legalEntity.service';
import { LegalEntityService } from './legalEntity.service';
export * from './liveChat.service';
import { LiveChatService } from './liveChat.service';
export * from './liveChatTranslation.service';
import { LiveChatTranslationService } from './liveChatTranslation.service';
export * from './notesDocumentation.service';
import { NotesDocumentationService } from './notesDocumentation.service';
export * from './notificationCenter.service';
import { NotificationCenterService } from './notificationCenter.service';
export * from './notificationValidation.service';
import { NotificationValidationService } from './notificationValidation.service';
export * from './otp.service';
import { OtpService } from './otp.service';
export * from './ping.service';
import { PingService } from './ping.service';
export * from './plugin.service';
import { PluginService } from './plugin.service';
export * from './profile.service';
import { ProfileService } from './profile.service';
export * from './quota.service';
import { QuotaService } from './quota.service';
export * from './registrationValidation.service';
import { RegistrationValidationService } from './registrationValidation.service';
export * from './report.service';
import { ReportService } from './report.service';
export * from './staticTemplate.service';
import { StaticTemplateService } from './staticTemplate.service';
export * from './subscription.service';
import { SubscriptionService } from './subscription.service';
export * from './syncAppointment.service';
import { SyncAppointmentService } from './syncAppointment.service';
export * from './validateEmail.service';
import { ValidateEmailService } from './validateEmail.service';
export const APIS = [AnalyticsService, ApplicationSettingsService, AppointmentService, AppointmentDashboardService, AppointmentRecordingService, AssignInquiryService, AttachmentService, AttachmentCvService, AuthenticationTokenService, BrandingColorService, BrandingInvitationService, CompanyService, CompanyAddressService, CompanyLoginService, DistanceService, EmailAvailableService, EmployeeService, EmployeeInviteService, EmployeeOtpService, FeatureTrackingService, InitializationStatusService, InquiryService, InquiryAccessService, InquiryDashboardService, InquiryEditService, InquirySettingsService, InvitationStatusService, LegalEntityService, LiveChatService, LiveChatTranslationService, NotesDocumentationService, NotificationCenterService, NotificationValidationService, OtpService, PingService, PluginService, ProfileService, QuotaService, RegistrationValidationService, ReportService, StaticTemplateService, SubscriptionService, SyncAppointmentService, ValidateEmailService];
