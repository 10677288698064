import { Component, Input, signal } from '@angular/core';
import { Employee } from '../../model/employee/employee';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { KeycloakInitiatedActionStatus } from '../../authentication/initiated-action.service';
import { MatDialog } from '@angular/material/dialog';
import {
  EditAuthenticationDialogComponent,
  EditAuthenticationDialogComponentData,
} from '../dialogs/edit-authentication-dialog/edit-authentication-dialog.component';
import { EmployeeOtpService } from '../../services/api/employee-otp.service';
import { OtpInfo } from '../../model/employee/otp/otp-info';

@Component({
  selector: 'app-personal-information-authentication',
  templateUrl: './personal-information-authentication.component.html',
  styleUrls: ['./personal-information-authentication.component.scss'],
  providers: [EmployeeOtpService],
})
export class PersonalInformationAuthenticationComponent {
  @Input() employee: Employee;
  isLoading$ = signal(true);
  twoFactorEnabled$ = signal<boolean>(false);
  otpInfo: OtpInfo[] = [];

  constructor(
    private readonly employeeOtpService: EmployeeOtpService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly router: Router,
    private readonly snackBar: MatSnackBar,
    private readonly dialog: MatDialog,
  ) {
    this.activatedRoute.queryParams.subscribe((params) => {
      const actionStatus = params['kc_action_status'];
      this.showPasswordChangeActionInformation(actionStatus);
      this.router.navigate(['profile']);
    });

    this.reloadOtpInformation();
  }

  onEdit() {
    const dialogRef = this.dialog.open(EditAuthenticationDialogComponent, {
      data: { otpInfo: this.otpInfo } as EditAuthenticationDialogComponentData,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.reloadOtpInformation();
        this.snackBar.open(
          $localize`Einstellungen wurden gespeichert.`,
          $localize`Ok`,
          { duration: 3000 },
        );
      }
    });
  }

  private showPasswordChangeActionInformation(actionStatus: string) {
    if (actionStatus === KeycloakInitiatedActionStatus.Success) {
      this.snackBar.open(
        $localize`Passwort wurde erfolgreich geändert.`,
        $localize`Ok`,
        {
          duration: 3_000,
        },
      );
    } else if (actionStatus === KeycloakInitiatedActionStatus.Cancelled) {
      this.snackBar.open(
        $localize`Änderung des Passworts wurde abgebrochen.`,
        $localize`Ok`,
        {
          duration: 3_000,
        },
      );
    } else if (actionStatus === KeycloakInitiatedActionStatus.Error) {
      this.snackBar.open(
        $localize`Fehler beim Ändern deines Passwortes.`,
        $localize`Ok`,
        {
          duration: 3_000,
        },
      );
    }
  }

  private reloadOtpInformation() {
    this.employeeOtpService.getOtpInfo().subscribe((res) => {
      this.otpInfo = res;
      this.twoFactorEnabled$.set(res.length > 0);
      this.isLoading$.set(false);
    });
  }
}
