<mat-card class="authentication-container">
  <app-card-header-with-action
    i18n-title
    title="Authentifizierung"
    (actionClicked)="onEdit()"
    i18n-actionText
    actionText="Bearbeiten"
    [actionEnabled]="!isLoading$()"
  ></app-card-header-with-action>

  <div *ngIf="employee">
    <mat-list>
      <mat-list-item>
        <mat-icon matListItemIcon>lock</mat-icon>
        <div matListItemTitle class="ns">••••••••</div>
      </mat-list-item>
      <mat-list-item>
        <mat-icon matListItemIcon>security</mat-icon>
        <div matListItemTitle>
          <div class="two-factor-container">
            <div class="ns">2-Faktor-Authentifizierung</div>
            @if (twoFactorEnabled$()) {
              <mat-icon class="two-factor-icon">verified</mat-icon>
            } @else {
              <mat-icon class="two-factor-icon-unverified">error</mat-icon>
            }
          </div>
        </div>
      </mat-list-item>
    </mat-list>
  </div>
</mat-card>
