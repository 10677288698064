import { filter, from, map, Observable, of } from 'rxjs';
import { first } from 'rxjs/operators';
import { Devices, DeviceType } from '../device.service';

export class CameraSelectionUtil {
  public static fetchDeviceOptions(): Observable<Devices> {
    return from(navigator.mediaDevices.enumerateDevices()).pipe(
      map((devices) => {
        return [
          DeviceType.AUDIO_INPUT,
          DeviceType.AUDIO_OUTPUT,
          DeviceType.VIDEO_INPUT,
        ].reduce((options, kind) => {
          return options.concat(
            devices.filter((device) => device.kind === kind && device.label),
          );
        }, [] as Devices);
      }),
    );
  }

  private static getSpecificDevice(
    getBackCam: boolean,
  ): Observable<MediaDeviceInfo> {
    // using facing allows us to differentiate between android phones and iphones with english system language (Back/Front Camera)
    const label = getBackCam ? 'facing back' : 'facing front';

    return this.fetchDeviceOptions().pipe(
      filter((x) => !!x.length),
      first(),
      map((devices) => {
        const videoDevices = devices.filter((x) => x.kind === 'videoinput');
        const cams = videoDevices.filter((x) =>
          x.label.toLowerCase().includes(label),
        );
        if (cams.length >= 1) {
          // This is basically the android scenario, as labels are english in here
          // yet, some phones dont put the default back cam on first index, but sort them via label like "camera2, 0"
          const sorted = this.sortByLabel(cams);
          return sorted[0];
        } else {
          // This is basically the ios scenario, as labels are language dependent here
          // Explanation: https://stackoverflow.com/questions/65485170/getusermedia-detect-front-camera
          if (getBackCam) {
            return videoDevices.length >= 2 ? videoDevices[1] : videoDevices[0];
          } else {
            return videoDevices[0];
          }
        }
      }),
    );
  }

  public static getRearCameraDeviceId(): Observable<MediaDeviceInfo> {
    return CameraSelectionUtil.getSpecificDevice(true);
  }

  public static getFrontCameraDeviceId(): Observable<MediaDeviceInfo> {
    return CameraSelectionUtil.getSpecificDevice(false);
  }

  private static sortByLabel(devices: MediaDeviceInfo[]): MediaDeviceInfo[] {
    return devices.sort((a, b) => {
      return a.label.localeCompare(b.label);
    });
  }
}
