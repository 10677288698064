import { PhoneNumberValidationDto } from '../../../../api/gen';
import { LineTypeIntelligence } from './line-type-intelligence';
import { PhoneNumberValidationError } from './phone-number-validation-error';

export class PhoneNumberValidation {
  callingCountryCode?: string | null;
  countryCode?: string | null;
  phoneNumber?: string | null;
  nationalFormat?: string | null;
  valid?: boolean | null;
  validationErrors?: PhoneNumberValidationError[] | null;
  lineTypeIntelligence?: LineTypeIntelligence;

  public static fromDto(dto: PhoneNumberValidationDto): PhoneNumberValidation {
    return {
      callingCountryCode: dto.callingCountryCode,
      countryCode: dto.countryCode,
      phoneNumber: dto.phoneNumber,
      nationalFormat: dto.nationalFormat,
      valid: dto.valid,
      validationErrors:
        dto.validationErrors != null
          ? dto.validationErrors.map((dto) =>
              PhoneNumberValidationError.fromDto(dto),
            )
          : null,
      lineTypeIntelligence:
        dto.lineTypeIntelligence != null
          ? LineTypeIntelligence.fromDto(dto.lineTypeIntelligence)
          : null,
    } as PhoneNumberValidation;
  }
}
