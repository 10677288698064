import {
  catchError,
  filter,
  first,
  from,
  Observable,
  of,
  Subject,
  tap,
} from 'rxjs';
import { Injectable } from '@angular/core';
import { distinctUntilChanged, map, mergeMap } from 'rxjs/operators';
import { AidarAudioTrack, AidarVideoTrack } from './aidar-media-track';
import { PermissionService } from '../permission.service';
import { CameraSelectionUtil } from './camera-selection-util';
import { DeviceService, SettingUpdateType } from '../device.service';

@Injectable({
  providedIn: 'root',
})
export class MediaTrackService {
  public mediaStreamUpdated$ = new Subject<MediaStream | null>();
  public videoTrackUpdated$ = new Subject<AidarVideoTrack | null>();
  public audioTrackUpdated$ = new Subject<AidarAudioTrack | null>();

  private mediaStream?: MediaStream;
  private audioTrack?: AidarAudioTrack;
  private videoTrack?: AidarVideoTrack;

  private readonly defaultCameraConstraints = {
    facingMode: { ideal: 'user' },
    width: { ideal: 853, min: 640, max: 1920 }, // not using 640:480 to keep aspectRatio
    height: { ideal: 480, min: 480, max: 1080 },
    frameRate: { ideal: 60, min: 10, max: 60 },
    aspectRatio: 16 / 9,
  } as MediaTrackConstraints;

  private readonly defaultMicConstraints = {
    noiseSuppression: true,
    echoCancellation: true,
  } as MediaTrackConstraints;

  constructor(
    private readonly newPermissionService: PermissionService,
    private readonly deviceService: DeviceService,
  ) {
    this.mediaStreamUpdated$.subscribe((stream) => {
      this.mediaStream = stream;
      this.audioTrack = stream?.getAudioTracks()[0]
        ? new AidarAudioTrack(stream?.getAudioTracks()[0])
        : null;
      this.audioTrackUpdated$.next(this.audioTrack);
      this.videoTrack = stream?.getVideoTracks()[0]
        ? new AidarVideoTrack(stream?.getVideoTracks()[0])
        : null;
      this.videoTrackUpdated$.next(this.videoTrack);
    });

    // Those are needed to ensure we set the device id of the settings component
    // accordingly. As we cannot be sure if we really get the requested device.
    // Also, this one sets the device id as well if we dont have one in the local
    // storage yet. In this case, we get the user facing cam, and set it the settings
    // for the future
    this.audioTrackUpdated$
      .pipe(
        filter((x) => !!x),
        first(),
      )
      .subscribe((x) => {
        this.deviceService.setAudioDevice(
          x.getMediaStreamTrack().getSettings().deviceId,
        );
      });
    this.videoTrackUpdated$
      .pipe(
        filter((x) => !!x),
        first(),
      )
      .subscribe((x) => {
        this.deviceService.setVideoDevice(
          x.getMediaStreamTrack().getSettings().deviceId,
        );
      });

    this.mediaStreamUpdated$
      .pipe(
        filter((x) => !!x),
        distinctUntilChanged(),
        mergeMap((_) => CameraSelectionUtil.fetchDeviceOptions()),
        distinctUntilChanged(),
      )
      .subscribe((devices) => {
        this.deviceService.publishDeviceOptions(devices);
      });

    this.deviceService.settingsChanged$.subscribe((x) => {
      if (x.updateType === SettingUpdateType.AudioInput) {
        this.changeAudioDevice(x.deviceId);
      } else if (x.updateType === SettingUpdateType.VideoInput) {
        this.changeVideoDevice(x.deviceId);
      }
    });
  }

  private areMediaDevicesSupported(): boolean {
    return !!navigator.mediaDevices && !!navigator.mediaDevices.getUserMedia;
  }

  public initialCreation(): Observable<boolean> {
    if (!this.areMediaDevicesSupported()) {
      return of(false);
    }
    return this.createMediaStreams().pipe(
      map((stream) => !!stream),
      tap((x) => {
        // I wanted to put the permission query logic into the permission service at first
        // however, we need to create a media stream in order to really fetch the permission. destroying it again
        // would cause problems in firefox for device enumeration and as "prompt" permissions are prompting everytime
        // we create a track even in the same session. thus, saving the tracks used for permission queries are the
        // way to go
        if (x) this.newPermissionService.permissionSuccess();
        else this.newPermissionService.detectSystemLevelDenied();
      }),
    );
  }

  private createMediaStreams(): Observable<MediaStream | null> {
    return this.getUserMediaStreamForDeviceSettings().pipe(
      map((x) => {
        this.mediaStreamUpdated$.next(x);
        return x;
      }),
      catchError((err) => {
        this.mediaStreamUpdated$.next(null);
        console.error('Media Stream Creation Failed:', err);
        return of(null);
      }),
    );
  }

  public clearTracks(): void {
    this.audioTrack?.destroy();
    this.videoTrack?.destroy();
    // Just to be sure
    this.mediaStream?.getTracks().forEach((track) => track.stop());
    this.mediaStream = null;
    this.mediaStreamUpdated$.next(null);
  }

  getAudioTrack(): AidarAudioTrack | undefined {
    return this.audioTrack;
  }

  getVideoTrack(): AidarVideoTrack | undefined {
    return this.videoTrack;
  }

  private changeAudioDevice(deviceId: string) {
    this.audioTrack?.destroy();
    this.mediaStream.getAudioTracks().forEach((track) => {
      track.enabled = false;
      track.stop();
      this.mediaStream.removeTrack(track);
    });
    // Needed in order to "unpublish" current track
    this.audioTrackUpdated$.next(null);
    this.getAudioStreamByDeviceId(deviceId).subscribe((x) => {
      x.getAudioTracks().forEach((track) => {
        this.mediaStream.addTrack(track);
      });
      this.audioTrack = x.getAudioTracks()[0]
        ? new AidarAudioTrack(x.getAudioTracks()[0])
        : null;
      this.audioTrackUpdated$.next(this.audioTrack);
    });
  }

  private changeVideoDevice(deviceId: string) {
    this.videoTrack?.destroy();
    this.mediaStream.getVideoTracks().forEach((track) => {
      track.enabled = false;
      track.stop();
      this.mediaStream.removeTrack(track);
    });
    // Needed in order to "unpublish" current track
    this.videoTrackUpdated$.next(null);
    this.getVideoStreamByDeviceId(deviceId).subscribe((x) => {
      x.getVideoTracks().forEach((track) => {
        this.mediaStream.addTrack(track);
      });
      this.videoTrack = x.getVideoTracks()[0]
        ? new AidarVideoTrack(x.getVideoTracks()[0])
        : null;
      this.videoTrackUpdated$.next(this.videoTrack);
    });
  }

  private getUserMediaStreamForDeviceSettings(): Observable<MediaStream> {
    const videoSettings = { ...this.defaultCameraConstraints };
    const audioSettings = { ...this.defaultMicConstraints };

    const videoSelection = this.deviceService.getSelectedVideoDeviceId();
    const audioSelection = this.deviceService.getSelectedAudioDeviceId();

    if (videoSelection) {
      videoSettings.facingMode = null;
      videoSettings.deviceId = { ideal: videoSelection };
    }
    if (audioSelection) {
      audioSettings.deviceId = { ideal: audioSelection };
    }
    // In case the device id is randomized, we would actually need to check again after track creation
    // if we really got the desired track. If not, we could still fallback to the facing mode
    return from(
      navigator.mediaDevices.getUserMedia({
        video: videoSettings,
        audio: audioSettings,
      }),
    );
  }

  private getAudioStreamByDeviceId(deviceId: string): Observable<MediaStream> {
    const audioSettings = { ...this.defaultMicConstraints };
    audioSettings.deviceId = { ideal: deviceId };
    return from(
      navigator.mediaDevices.getUserMedia({
        video: false,
        audio: audioSettings,
      }),
    );
  }

  private getVideoStreamByDeviceId(deviceId: string): Observable<MediaStream> {
    const videoSettings = { ...this.defaultCameraConstraints };
    videoSettings.facingMode = null;
    videoSettings.deviceId = { ideal: deviceId };
    return from(
      navigator.mediaDevices.getUserMedia({
        video: videoSettings,
        audio: false,
      }),
    );
  }
}
