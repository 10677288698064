export class AppointmentCommands {
  public static readonly SYNC = 'appointment_sync';

  public static readonly SYNC_ATTACHMENTS = 'appointment_syncAttachments';

  public static readonly HANG_UP = 'appointment_hangUp';

  public static readonly IS_RECORDING = 'appointment_isRecording';

  public static readonly INVITATION_STATUS_CHANGED =
    'appointment_invitationStatusChanged';

  public static readonly PUBLISH_NEW_CHAT_MESSAGE =
    'appointment_newChatMessage';

  public static readonly CANCEL_CREATE_SCREENSHOT =
    'appointment_cancelCreateScreenshot';
}
