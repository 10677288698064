import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { CanvasSyncService } from '../../../annotations/services/canvas-sync.service';
import {
  DrawEvent,
  DrawingCanvasComponent,
  Mode,
} from '../../../annotations/drawing-canvas/drawing-canvas.component';
import { ParticipantsComponent } from '../../shared/participants/participants.component';
import { PictureOverlayComponent } from '../../picture-overlay/picture-overlay.component';
import { VideoChatService } from '../../services/videochat.service';
import {
  ContentType,
  VideochatContentService,
} from '../../services/videochat-content.service';
import { CallCtrlService } from '../../services/call-ctrl.service';
import {
  AppointmentAttachmentsService,
  CallAttachment,
} from '../../services/appointment-attachments.service';
import { MatDialog } from '@angular/material/dialog';
import { CapabilitiesService } from '../../services/capabilities.service';
import { filter, first, last, map, Observable, Subject } from 'rxjs';
import { EndCallDialogComponent } from '../../dialogs/end-call-dialog/end-call-dialog.component';
import { takeUntil } from 'rxjs/operators';
import { SlideInDirection } from '../../new-attachment-preview/new-attachment-preview.component';
import { AllowedControls } from '../../shared/content/present-image/present-image.component';
import { RemoteParticipant } from 'twilio-video';
import { Participant } from 'twilio-video/tsdef/Participant';
import { AttachmentContentType } from '../../../model/attachment/attachment-content-type';
import { LiveImageInProgressOverlayComponent } from '../../live-image-in-progress-overlay/live-image-in-progress-overlay.component';
import { DiagnosticsService } from '../../services/diagnostics/diagnostics.service';
import {
  DataTrackObject,
  DataTrackObjectType,
  DataTrackService,
} from '../../services/track-ctrl/data-track.service';
import {
  AppointmentMessageType,
  AppointmentMessagingService,
} from '../../../services/signaling/messaging/appointment-messaging.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-landscape-appointment',
  templateUrl: './landscape-appointment.component.html',
  styleUrls: ['./landscape-appointment.component.scss'],
})
export class LandscapeAppointmentComponent implements AfterViewInit, OnDestroy {
  @ViewChild('drawingCanvas') drawingCanvas: DrawingCanvasComponent;
  @ViewChild('participants') remoteVideo: ParticipantsComponent;
  @ViewChild('screenshotOverlay') screenshotOverlay: PictureOverlayComponent;
  @ViewChild('liveImageOverlay')
  liveImageOverlay: LiveImageInProgressOverlayComponent;
  @ViewChild('sizeWrapper') sizeWrapper: ElementRef;

  @Input()
  public inquiryId: string;

  currentAttachment: CallAttachment;
  AttachmentType = AttachmentContentType;
  ContentType = ContentType;

  protected isPictureInProgress = false;

  private readonly unsubscribe$ = new Subject<void>();

  protected slideInDirection = SlideInDirection.X;
  protected allowedImageCtrls: AllowedControls = {
    ocr: true,
    edit: true,
    present: true,
  };

  protected remoteVideoTrackEnabled$: Observable<boolean>;

  constructor(
    public readonly dataTrackCtrl: DataTrackService,
    private readonly dialog: MatDialog,
    public readonly videoChatService: VideoChatService,
    protected readonly canvasSyncService: CanvasSyncService,
    protected readonly videochatContentService: VideochatContentService,
    protected readonly callCtrlService: CallCtrlService,
    private readonly attachmentService: AppointmentAttachmentsService,
    private readonly appointmentMessagingService: AppointmentMessagingService,
    private readonly capabilitiesService: CapabilitiesService,
    private readonly diagnostics: DiagnosticsService,
    private readonly snackBar: MatSnackBar,
  ) {
    this.remoteVideoTrackEnabled$ = this.diagnostics.diagnosticsChanged$.pipe(
      map((x) => x.remoteCameraTurnedOn),
    );
    this.attachmentService
      .onCurrentAttachment()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((x) => {
        this.currentAttachment = x?.attachments[x?.currentIndex];
      });

    this.appointmentMessagingService
      .onMessage(AppointmentMessageType.CancelCreateScreenshot)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.isPictureInProgress = false;
        this.snackBar.open(
          $localize`Kunde hat Foto nicht freigegeben!`,
          $localize`Ok`,
          { duration: 3000 },
        );
      });

    this.videoChatService.activeRoom$
      .pipe(filter((x) => !!x))
      .subscribe((activeRoom) => {
        if (activeRoom.room) {
          this.participantsChanged(activeRoom.room.participants);
          activeRoom.room
            .on('participantConnected', () =>
              this.participantsChanged(activeRoom.room.participants),
            )
            .on('participantDisconnected', () =>
              this.participantsChanged(activeRoom.room.participants),
            )
            .on('participantReconnected', () =>
              this.participantsChanged(activeRoom.room.participants),
            );
        }
      });

    this.capabilitiesService.capabilitiesChanged$
      .pipe(
        takeUntil(this.unsubscribe$),
        map((x) => {
          return { width: x.cameraWidth, height: x.cameraHeight, zoom: x.zoom };
        }),
      )
      .subscribe((x) => {
        if (this.sizeWrapper) {
          this.sizeWrapper.nativeElement.style.aspectRatio =
            x.width + '/' + x.height;

          // TODO: we might be able to avoid setting the style of both elements
          if (x.height > x.width) {
            this.sizeWrapper.nativeElement.style.height = '100%';
            this.sizeWrapper.nativeElement.style.width = 'auto';
          } else {
            this.sizeWrapper.nativeElement.style.height = 'auto';
            this.sizeWrapper.nativeElement.style.width = '100%';
          }
        }
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  public drawOnVideo(data: DrawEvent) {
    this.videoChatService.drawOnVideo(data);
  }

  public clearCanvas() {
    this.drawingCanvas.clear();
    this.drawOnVideo({ mode: Mode.Delete });
  }

  public callEndedClicked() {
    this.endCall();
  }

  private endCall() {
    const dialogRef = this.dialog.open(EndCallDialogComponent);

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.videoChatService.endCall();
      }
    });
  }

  public async takeScreenshot() {
    this.isPictureInProgress = true;
    this.attachmentService.newAttachment$.pipe(first()).subscribe(() => {
      this.isPictureInProgress = false;
    });
    this.dataTrackCtrl.sendDataObject(
      DataTrackObject.withType(DataTrackObjectType.SCREENSHOT),
    );
    this.screenshotOverlay.takeScreenshot();
  }

  public async recordLiveImage() {
    this.isPictureInProgress = true;
    this.attachmentService.newAttachment$.pipe(first()).subscribe(() => {
      this.isPictureInProgress = false;
    });
    this.dataTrackCtrl.sendDataObject(
      DataTrackObject.withType(DataTrackObjectType.LIVE_IMAGE),
    );
    this.screenshotOverlay
      .takeLivePhoto()
      .pipe(last())
      .subscribe(() => {
        this.liveImageOverlay.showRecording();
      });
  }

  public modeChanged(event: Mode) {
    this.callCtrlService.modeChannel$.next(event);
  }

  protected closeAttachment() {
    this.attachmentService.changeCurrentAttachment(null);
  }

  ngAfterViewInit(): void {
    this.videoChatService.activeRoom$
      .pipe(
        filter((x) => !!x?.room),
        first(),
      )
      .subscribe(() => {
        this.canvasSyncService.referenceElementChanged(
          this.sizeWrapper.nativeElement,
        );
      });
  }

  // NOT that beautiful way of bringing the invitation dialog to the front and capture input
  private participantsChanged(
    participants: Map<Participant.SID, RemoteParticipant>,
  ) {
    if (participants.size > 0) {
      document.getElementById('participants').style.zIndex = '0';
      document.getElementById('picture-overlay').style.zIndex = '1';
    } else if (participants.size === 0) {
      document.getElementById('participants').style.zIndex = '2';
      document.getElementById('picture-overlay').style.zIndex = '1';
    }
  }
}
